const Products = require('../products/products');

const addons = [
    {
        name: 'Custom Digital Signage',
        description: 'Custom displays can be built with information from SpeedyDock to display to customers or for viewing from the office. Show customers their place in line upon arrival or show useful stats for marina staff.',
        isComingSoon: false,
        isNew: false,
        photoSrc: '/addons/digital-signage.jpg',
        products: [Products.DRY_STACK.name, Products.BOAT_CLUB.name],
        urlSlug: 'digital-signage',
    },
    {
        name: 'Live Stream Video',
        description: 'Marina camera live stream available for display in-app for customers to view or for embedding on a website.',
        isComingSoon: false,
        isNew: false,
        photoSrc: '/addons/live-stream.jpg',
        products: [Products.DRY_STACK.name, Products.BOAT_CLUB.name],
        urlSlug: 'live-stream',
    },
    {
        name: 'Customer Check-In Kiosk',
        description: 'Customers can check-in at the marina by either scanning a QR code from their SpeedyDock app or with a check-in code.',
        isComingSoon: false,
        isNew: true,
        products: [Products.DRY_STACK.name, Products.BOAT_CLUB.name],
        urlSlug: 'customer-kiosk',
    },
];

module.exports = addons;
